<template>
    <main>
        <NuxtLoadingIndicator />

        <slot />

        <div class="container mx-auto py-5 px-4">
            <div class="flex text-center w-full justify-center text-xs">
                <a href="mailto:info@softbox.asia" target="_blank">Powered by Firefly Ideas</a>
            </div>
        </div>
    </main>
</template>
<script setup>
const config = useRuntimeConfig();
const colors = (config.public.THEME_COLORS || '#B3A37A,#6a5c36,#B3A37A').split(',');

onMounted(() => {
    if (document) {
        document.documentElement.style.setProperty('--primary', colors[0]);
        document.documentElement.style.setProperty('--secondary', colors[1]);
        document.documentElement.style.setProperty('--button', colors[2]);
    }
})
</script>